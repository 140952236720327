exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-medina-contacts-page-index-tsx": () => import("./../../../src/templates/MedinaContactsPage/index.tsx" /* webpackChunkName: "component---src-templates-medina-contacts-page-index-tsx" */),
  "component---src-templates-medina-deals-page-index-tsx": () => import("./../../../src/templates/MedinaDealsPage/index.tsx" /* webpackChunkName: "component---src-templates-medina-deals-page-index-tsx" */),
  "component---src-templates-medina-gallery-page-index-tsx": () => import("./../../../src/templates/MedinaGalleryPage/index.tsx" /* webpackChunkName: "component---src-templates-medina-gallery-page-index-tsx" */),
  "component---src-templates-medina-home-page-index-tsx": () => import("./../../../src/templates/MedinaHomePage/index.tsx" /* webpackChunkName: "component---src-templates-medina-home-page-index-tsx" */),
  "component---src-templates-medina-quotation-page-index-tsx": () => import("./../../../src/templates/MedinaQuotationPage/index.tsx" /* webpackChunkName: "component---src-templates-medina-quotation-page-index-tsx" */),
  "component---src-templates-medina-rooms-page-index-tsx": () => import("./../../../src/templates/MedinaRoomsPage/index.tsx" /* webpackChunkName: "component---src-templates-medina-rooms-page-index-tsx" */),
  "component---src-templates-medina-services-page-index-tsx": () => import("./../../../src/templates/MedinaServicesPage/index.tsx" /* webpackChunkName: "component---src-templates-medina-services-page-index-tsx" */),
  "component---src-templates-policy-page-index-tsx": () => import("./../../../src/templates/PolicyPage/index.tsx" /* webpackChunkName: "component---src-templates-policy-page-index-tsx" */),
  "component---src-templates-principe-contacts-page-index-tsx": () => import("./../../../src/templates/PrincipeContactsPage/index.tsx" /* webpackChunkName: "component---src-templates-principe-contacts-page-index-tsx" */),
  "component---src-templates-principe-deals-page-index-tsx": () => import("./../../../src/templates/PrincipeDealsPage/index.tsx" /* webpackChunkName: "component---src-templates-principe-deals-page-index-tsx" */),
  "component---src-templates-principe-gallery-page-index-tsx": () => import("./../../../src/templates/PrincipeGalleryPage/index.tsx" /* webpackChunkName: "component---src-templates-principe-gallery-page-index-tsx" */),
  "component---src-templates-principe-home-page-index-tsx": () => import("./../../../src/templates/PrincipeHomePage/index.tsx" /* webpackChunkName: "component---src-templates-principe-home-page-index-tsx" */),
  "component---src-templates-principe-quotation-page-index-tsx": () => import("./../../../src/templates/PrincipeQuotationPage/index.tsx" /* webpackChunkName: "component---src-templates-principe-quotation-page-index-tsx" */),
  "component---src-templates-principe-rooms-page-index-tsx": () => import("./../../../src/templates/PrincipeRoomsPage/index.tsx" /* webpackChunkName: "component---src-templates-principe-rooms-page-index-tsx" */),
  "component---src-templates-principe-services-page-index-tsx": () => import("./../../../src/templates/PrincipeServicesPage/index.tsx" /* webpackChunkName: "component---src-templates-principe-services-page-index-tsx" */)
}

